import React, { useState } from 'react';
import { ArrowUpRightIcon, ChevronDownIcon, ChatBubbleBottomCenterIcon, EnvelopeOpenIcon, PaperAirplaneIcon, CakeIcon } from '@heroicons/react/24/solid';
import { createCode } from '../../util/db';
import { useAuth } from '../../util/auth';
import {apiRequest} from "../../util/util"

const SendButtons = () => {
    const auth = useAuth()
  // State to track if the dropdown is open or not
  const [isOpen, setIsOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [language, setLanguage] = useState("English");
  const [method, setMethod] = useState("SMS")
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [successfullSend,setSuccessfullSend] = useState(false);


  //for sending: experimental
    const [email, setEmail] = useState('');
    const [sms, setSMS] = useState('');
    const [sender, setSender] = useState(auth.user.name)
    const [isChecked, setIsChecked] = useState(false)
    const [response, setResponse] = useState(null);

  // Function to toggle the dropdown open and close
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  function generateRandomHash() {
    const array = new Uint8Array(16);
    window.crypto.getRandomValues(array);
    return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
  }
  

  function closePopup() {
    setIsPopupOpen(false)
    setSuccessfullSend(false)
    setButtonDisabled(false)
  }

  const sendCode = async (methodSort) =>  {
    setButtonDisabled(true)
    var hash = generateRandomHash()
    var message = `Hello, this is ${sender}. Here is your 24-hour URL: https://beta.insuranceagencytraining.org/view/${hash}`
    try {
      await createCode(hash, {
        owner: auth.user.uid, 
        opened: false,
        method: method, 
        language: language, 
        recipients: (method==="Both")?[sms, email]:(method==="SMS")?[sms]:[email] 
      })
      const data = {
            sms: sms,
            message: message,
            email: email,
            method: methodSort,
            subject: "Your 24-Hour URL"
        };

        const response = await apiRequest('send-message', 'POST', data);
        console.log('Response after sending code:', response);
        setSuccessfullSend(true)
    } catch (error) {
      console.error("Error creating code: ", error)
      setSuccessfullSend(false)
    }
    setButtonDisabled(false)
    setSMS("")
    setEmail("")
  }

  return (
    <>
    {isPopupOpen && (
        <div className="z-20 fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center">
          {/* Popup content */}
          <div className="bg-white p-8 rounded-lg shadow-lg lg:w-1/2 w-5/6 max-w-4xl relative">
            {successfullSend===true?(
            <div className="flex flex-col text-center space-y-4">
            <CakeIcon className='h-12 text-green-600'/>
            <h2 className='text-5xl font-semibold'>One-Time Code Access Has Been Sent Successfully!</h2>
            <p className='text-lg font-semithin'>{`We're excited to inform you that the one-time access code has been successfully sent.`} </p>
            <p className='text-lg text-gray-400'>Note: Access to the link will expire in 24 hours.</p>
            <button onClick={()=>setSuccessfullSend(false)} className="flex flex-row bg-green-600 text-gray-200 py-2 px-4 w-full justify-center rounded-md focus:outline-none focus:border-green-700">Send Another Code</button>

            <button onClick={()=> closePopup()} className=" absolute top-0 right-4 text-3xl text-gray-400 rounded-md">
            &times;
          </button>
          </div>
          ):(
            <>
                        <h2 className=" text-center text-5xl font-bold pb-6">Send to Client</h2>
            <p className='text-gray-600 text-center'>Send Stairs and Buckets video to clients who can't make it into your office. Select options below.</p>
            <button onClick={()=> closePopup()} className=" absolute top-0 right-4 text-3xl text-gray-400 rounded-md">
              &times;
            </button>
            <p className='text-gray-400 text-left m-2'>Select a Language</p>
            <span className="isolate lg:inline-flex rounded-md mb-4">
                <button
                    onClick={()=>{setLanguage("English")}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${language==="English"?"bg-green-100 text-green-600 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    English
                </button>
                <button
                    onClick={()=>{setLanguage("Spanish")}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${language==="Spanish"?"bg-green-100 text-green-600 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Spanish
                </button>
                <button
                    onClick={()=>{setLanguage("Mandarin")}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${language==="Mandarin"?"bg-green-100 text-green-600 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Mandarin
                </button>
                <button
                    onClick={()=>{setLanguage("Cantonese")}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${language==="Cantonese"?"bg-green-100 text-green-600 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Cantonese
                </button>
            </span>
            <p className='text-gray-400 text-left m-2'>How would you like to send this link?</p>
            <span className="isolate inline-flex rounded-md mb-4">
                <button
                    disabled={true}
                    onClick={()=>{setMethod("Email")}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${method==="Email"?"bg-green-100 text-green-600 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Email
                </button>
                <button
                    onClick={()=>{setMethod("SMS")}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${method==="SMS"?"bg-green-100 text-green-600 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    SMS
                </button>
                <button
                    onClick={()=>{setMethod("Both")}}
                    type="button"
                    disabled={true}
                    className={`relative inline-flex items-center rounded-full ${method==="Both"?"bg-green-100 text-green-600 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Both
                </button>
                <p className='text-gray-400 text-left m-2'>{"We apologize, sending emails is temporarily disabled. It will be working shortly :)"}</p>
            </span>
            {(method === "Email" || method==="Both")&&(
                <>
                <p className='text-gray-400 text-left m-2'>Recipient's Email</p>
            <div className='mb-4'>
                <label htmlFor="email" className="sr-only">
                    Email
                </label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Enter client's email here."
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}

                />
            </div>
            </>
            )}
            {(method === "SMS" || method==="Both")&&(
            <>
            <p className='text-gray-400 text-left m-2'>Recipient's Phone Number</p>
            <div className='mb-4'>
                <label htmlFor="email" className="sr-only">
                    Phone Number
                </label>
                <input
                    type="tel"
                    name="phone"
                    id="phone"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Enter client's phone number here."
                    value={sms}
                    onChange={(e) => setSMS(e.target.value)}

                />
            </div>
            </>)}
            <div className='h-0 my-4 border-t-4 border-dotted border-gray-200'></div>
            <p className='text-gray-400 text-left m-2'>Sender Name</p>
            <div className='mb-4'>
                <label htmlFor="email" className="sr-only">
                    Sender Name
                </label>
                <input
                    type="text"
                    name="name"
                    id="name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Sender's Full Name"
                    value={sender}
                    onChange={(e) => setSender(e.target.value)}
                />
            </div>
            <div className='w-full text-center pb-4'>
              <label>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <span style={{ marginLeft: "8px" }} className='text-xs text-gray-400'>
                Do you have permission to send this person a text message?
                By providing a phone number you agree to receive text messages on behalf of the recipient. By so doing you are confirming that you have written or verbal permission to do so. Messages will be sent from InsuranceAgencyTraining.org. Standard text messaging rates and data rates may apply. Frequency of messages may vary.                </span>
              </label>

            </div>
            <button onClick={()=>sendCode(method)} disabled={buttonDisabled || !isChecked} className="flex flex-row bg-green-600 text-gray-200 py-2 px-4 w-full justify-center rounded-md focus:outline-none focus:border-green-700">Send Access Code</button>
            </>)}

          </div>
        </div>
      )}
        <div className="relative inline-block text-left my-6">
      {/* Button to toggle the dropdown */}
      <button onClick={toggleDropdown} className="flex flex-row bg-green-600 text-gray-200 py-2 px-4 rounded-md focus:outline-none focus:border-green-700">
        Send To Clients
        <ArrowUpRightIcon className='h-6 mx-0.5'/>
        <ChevronDownIcon className='border-l h-6 ml-4 pl-4'/>
      </button>

      {isOpen && (
        <div className="origin-top-right absolute left-0 sm:left-44  mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <p className="block px-4 py-2 text-sm w-full text-left font-semibold" role="menuitem">Send Through: </p>
            <button className="flex px-4 py-2 text-sm w-full justify-center border-t border-grey-100 hover:bg-green-100" role="menuitem" onClick={() => {toggleDropdown()
            setIsPopupOpen(true);
            setMethod("SMS")
            }}>
            <EnvelopeOpenIcon className='h-5 mr-4'/>
            Send Email</button>
            <button className="flex px-4 py-2 text-sm w-full justify-center border-t border-grey-100 hover:bg-green-100" role="menuitem" onClick={() => {toggleDropdown()
            setIsPopupOpen(true);
            setMethod("SMS")
            }}>
            <ChatBubbleBottomCenterIcon className='h-5 mr-4'/>
            Send SMS</button>
            <button className="flex px-4 py-2 text-sm w-full justify-center border-t border-grey-100 hover:bg-green-100" role="menuitem" onClick={() => {toggleDropdown()
            setIsPopupOpen(true);
            setMethod("SMS")
            }}>
            <PaperAirplaneIcon className='h-5 mr-4'/>
            Send Both</button>
          </div>
        </div>
      )}
    </div>
    
    </>
  );
};

export default SendButtons;
